import * as React from "react"
import {
  Button,
  Card,
  Icon,
  Menu,
  MenuDivider,
  Position,
} from "@blueprintjs/core"
import {
  Popover2,
  Tooltip2,
  MenuItem2 as MenuItem,
} from "@blueprintjs/popover2"
import styled from "@emotion/styled"

import { useEffect, useRef } from "react"
import shave from "shave"
import cx from "classnames"
import { Transaction } from "models/Transaction"
import useCurrentUserDMSName from "features/dms/dmsName"

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 9px 10px;
  overflow: hidden;
  width: full;
  min-width: 150px;

  .title-contents {
    display: flex;
    align-items: center;
    overflow: hidden;

    .title-interior {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 1px;
    }
  }

  & a {
    text-decoration: none;
    font-size: 18px;
    color: #182026;
    letter-spacing: 1px;
    line-height: 21px;
  }

  & a::first-letter {
    text-transform: capitalize;
  }
`

type TransactionMenuProps = {
  id: Transaction["id"]
  onDelete?: () => void
  onArchive?: () => void
  onEdit?: () => void
  archived?: Transaction["archived"]
  view?: string
  expiredAt?: Transaction["expiredAt"]
  archivedFilesUrl?: string
}

export const TransactionMenu = ({
  id,
  expiredAt,
  archivedFilesUrl,
  onDelete,
  onArchive,
  onEdit,
  archived,
  view,
}: TransactionMenuProps) => {
  const dmsName = useCurrentUserDMSName()
  return (
    <Popover2
      position={Position.BOTTOM_LEFT}
      content={
        <Menu>
          {expiredAt && archivedFilesUrl && (
            <MenuItem
              icon="share"
              text={`View documents in ${dmsName}`}
              onClick={() => {
                window.location = archivedFilesUrl
              }}
            />
          )}
          {!expiredAt && (
            <MenuItem
              icon="add-to-folder"
              text={archived ? "Unarchive" : "Archive"}
              onClick={() => onArchive()}
            />
          )}
          <MenuItem icon="trash" text="Delete" onClick={() => onDelete()} />
          <MenuDivider />
          <MenuItem icon="cog" text="Edit..." onClick={() => onEdit()} />
        </Menu>
      }
    >
      <Button
        minimal
        icon={<Icon icon="more" color="#5C7080" />}
        className={view === "LIST" && "rotate-90"}
        data-testid={`transaction-menu-${id}`}
      />
    </Popover2>
  )
}

type TitleProps = {
  text: string
  children?: React.ReactNode
  onClick?: () => void
  view?: string
}

export const Title = ({
  text,
  children,
  onClick = () => null,
  view,
}: TitleProps) => {
  return (
    <TitleContainer>
      <div
        className={cx("title-contents", {
          "cursor-pointer": onClick() !== null,
          "mr-2": view === "LIST",
        })}
        onClick={() => {
          onClick()
        }}
      >
        <Tooltip2
          content={<div className="max-w-[325px]">{text}</div>}
          position={Position.LEFT}
        >
          <div className="title-interior">{text}</div>
        </Tooltip2>
      </div>

      <div
        className={cx("flex items-center", {
          "ml-auto space-x-1": view !== "LIST",
          "space-x-2": view === "LIST",
        })}
        onClick={(evt) => evt.preventDefault()}
      >
        {children}
      </div>
    </TitleContainer>
  )
}

type UnlinkedTitleProps = {
  text?: string
}

export const UnlinkedTitle = ({ text }: UnlinkedTitleProps) => {
  const ref = useRef()

  useEffect(() => {
    if (ref) shave(ref.current, 40)
  }, [ref])

  return (
    <TitleContainer>
      <Tooltip2 content={text} position={Position.TOP}>
        <span ref={ref}>{text}</span>
      </Tooltip2>
    </TitleContainer>
  )
}

export const ClientName = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`

export const Body = styled.div`
  font-size: 14px;
  color: #182026;
  text-align: left;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 36px);
  align-items: flex-start;

  & p {
    text-overflow: ellipsis;
    padding-left: 9px;
    margin: 8px 0;
    font-size: 12px;
    color: #182026;
    text-align: left;
    line-height: 15px;

    &:empty {
      display: none;
    }
  }

  &:not(:empty) {
    padding: 12px;
    border-top: 1px solid #dbdcdd;
  }
`

type DescriptionProps = {
  children?: React.ReactNode
}

export const Description = ({ children }: DescriptionProps) => {
  const bEl = useRef()

  useEffect(() => {
    if (bEl) shave(bEl.current, 60)
  }, [bEl])

  return <p ref={bEl}>{children}</p>
}

export const Bar = styled.div`
  width: ${(props) => `${props.progress}%`} !important;
  background: ${(props) => (props.green ? "#43BF4D" : "#F2B824")} !important;
  border-radius: ${(props) =>
    props.progress === 100 ? "0 0 3px 3px" : "0 3px 3px 3px"} !important;
`

type ProgressContainerProps = {
  children?: React.ReactNode
}

export const ProgressContainer = ({ children }: ProgressContainerProps) => (
  <div className="bp4-progress-bar bp4-no-stripes bp4-no-animation">
    {children}
  </div>
)

type ProgressBarProps = {
  progress?: string
  green?: string
}

export const ProgressBar = ({ progress, green }: ProgressBarProps) => (
  <Bar className="bp4-progress-meter" progress={progress} green={green} />
)

export const ProgressHint = styled(Tooltip2)`
  display: inline;
`

export const TransactionProgress = () => {
  return (
    <ProgressContainer>
      {/* <ProgressHint
          content="14 of 54 signatures collected"
          position={Position.BOTTOM}
          defaultIsOpen
        >
        </ProgressHint> */}
      <ProgressBar progress={60} />
      <ProgressBar progress={40} green />
    </ProgressContainer>
  )
}

export const TransactionCardContainer = styled(Card)`
  padding: 0;
  cursor: default !important;
  // Turning off default transition of transform
  // So magic grid can handle the transform animation
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);

  .bp4-progress-bar {
    border-radius: 0 0 3px 3px;
  }
`

type TransactionCardProps = {
  isExpired?: boolean
  view?: string
  children?: React.ReactNode
  interactive?: boolean
}

export const TransactionCard = ({
  isExpired,
  view,
  children,
  ...props
}: TransactionCardProps) => {
  return (
    <TransactionCardContainer
      className={cx("group", {
        "mb-3 flex w-full min-w-[460px] items-center": view === "LIST",
        "w-[268px]": view === "GRID",
        "bg-gray-sa-2 bg-opacity-40 text-gray-sa-5": isExpired,
      })}
      {...props}
    >
      {children}
    </TransactionCardContainer>
  )
}

TransactionCard.Title = Title
TransactionCard.UnlinkedTitle = UnlinkedTitle
TransactionCard.Menu = TransactionMenu
TransactionCard.Body = Body
TransactionCard.ClientName = ClientName
TransactionCard.Description = Description
TransactionCard.Progress = TransactionProgress
