import { InputGroup } from "@blueprintjs/core"
import { AreYouSure } from "components/modals/AreYouSure"

import { useState } from "react"

type TransactionDeleteModalProps = {
  transactionName?: string
  isOpen?: boolean
  onSubmit?: () => void
  onClose?: () => void
}

export const TransactionDeleteModal = ({
  transactionName = "Transaction Name",
  isOpen,
  onSubmit,
  onClose,
}: TransactionDeleteModalProps) => {
  const [fieldValue, setFieldValue] = useState("")

  const MATCHING_PHRASE = "DELETE"

  const submit = () => {
    if (fieldValue === MATCHING_PHRASE) {
      onSubmit()
    }
  }

  function handleSubmit(evt) {
    evt.preventDefault()
    submit()
  }

  return (
    <AreYouSure
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={submit}
      title="Delete transaction?"
      iconType="DANGER"
      disableConfirm={fieldValue.toUpperCase() !== MATCHING_PHRASE}
    >
      <p className="mb-3">
        You are about to delete <strong>{transactionName}</strong>. This action
        cannot be undone.
      </p>

      <form onSubmit={handleSubmit}>
        <label className="mb-1 block" htmlFor="confirm-delete">
          Type “DELETE” below to confirm.
        </label>
        <InputGroup
          id="confirm-delete"
          fill
          type="text"
          name="confirmationPhrase"
          value={fieldValue}
          onChange={(event) => setFieldValue(event.target.value)}
        />
      </form>
    </AreYouSure>
  )
}
