import * as React from "react"
import { useRouter } from "next/router"

import { Icon } from "@blueprintjs/core"

import UserEngagement from "app/user-engagement"
import { useTriggerSurvey } from "app/user-engagement/use-survey"

import TransactionForm from "components/forms/Transaction"
import Logo from "components/logo"
import { Sidebar } from "components/nav"
import {
  NotificationIcon,
  NotificationsFormatter,
} from "components/notification"

import UpgradeAccountModal from "features/transactions/UpgradeAccountModal"
import { useCreateTransaction } from "features/transactions/api"
import { useCurrentUser } from "features/auth/withAuth"
import { APITransaction, CreatableTransaction } from "models/Transaction"

type TransactionSidebarProps = {
  children?: React.ReactNode
}

export const TransactionSidebar = ({ children }: TransactionSidebarProps) => {
  const [isShowingUpgradeAccountModal, setIsShowingUpgradeAccountModal] =
    React.useState(false)

  const { currentUser } = useCurrentUser() as any // TODO-TS: Create type for currentUser ??
  const { triggerSurvey } = useTriggerSurvey()

  const { push } = useRouter()

  const { createTransaction } = useCreateTransaction({
    onError: ({ status }: { status: number }) => {
      if (status === 403) {
        setIsShowingUpgradeAccountModal(true)
      }
    },
    onSuccess: (transaction: APITransaction) => {
      triggerSurvey(currentUser)

      UserEngagement.track("TRANSACTION_CREATED", {
        id: transaction.uuid,
      })

      push("/transaction/[id]", `/transaction/${transaction.uuid}`)
    },
  })

  const handleCreateTransactionSubmit = async (
    {
      name: name,
      description,
      clientName,
      clientNumber,
      matterNumber,
      isDeedTransaction,
      isSample,
    }: CreatableTransaction,
    resetForm: () => void
  ) => {
    try {
      await createTransaction({
        name: isSample ? `${name} [Test]` : name,
        description,
        clientName,
        clientNumber,
        matterNumber,
        isDeedTransaction,
        isSample,
      })
    } catch (e) {
      console.log(e)
    }
    resetForm()
  }

  return (
    <div>
      <div className="fixed top-0 z-20 flex h-full">
        <Sidebar>
          {isShowingUpgradeAccountModal && (
            <UpgradeAccountModal
              onClose={() => setIsShowingUpgradeAccountModal(false)}
            />
          )}
          <Sidebar.Menu alwaysExpanded>
            <Sidebar.Logo>
              <Logo width="24px" height="24px" />
            </Sidebar.Logo>
            <Sidebar.MenuItem>
              <NotificationIcon color="#182026" size={20} />
            </Sidebar.MenuItem>
            <Sidebar.MenuItem defaultSelected>
              <Icon icon="add" color="#182026" size={20} />
            </Sidebar.MenuItem>
          </Sidebar.Menu>
          <Sidebar.Panels fullHeight>
            <Sidebar.MenuPanel title="Notifications" key="notifications">
              <NotificationsFormatter />
            </Sidebar.MenuPanel>
            <Sidebar.MenuPanel
              title="Create New Transaction"
              key="newTransaction"
            >
              <TransactionForm
                submitButtonProps={{}}
                handleSubmit={handleCreateTransactionSubmit}
              />
            </Sidebar.MenuPanel>
          </Sidebar.Panels>
        </Sidebar>
      </div>
      {children}
    </div>
  )
}
