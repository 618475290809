import styled from "@emotion/styled"

import { Tooltip2 } from "@blueprintjs/popover2"
import { Icon, AnchorButton, Intent, Tag, Button } from "@blueprintjs/core"
import { TransactionCard } from "."
import TransactionPrefetchLink from "features/transactions/TransactionPrefetchLink"
import { DownloadMenu } from "components/closing-binder"
import Link from "next/link"
import { Transaction } from "models/Transaction"

const ClientTextContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
`

type TransactionListCardProps = {
  apiUrl?: string
  archivedFilesUrl?: string
  handleArchiveToggle: () => void
  lastVisited?: boolean
  setDeleteModalOpen: (isOpen: boolean) => void
  setEditModalOpen: (isOpen: boolean) => void

  view?: string
} & Pick<
  Transaction,
  | "archived"
  | "clientName"
  | "clientNumber"
  | "closingBinder"
  | "createdAt"
  | "createdBy"
  | "description"
  | "expiredAt"
  | "id"
  | "matterNumber"
  | "name"
  | "outstandingSignatures"
>

export const TransactionListCard = ({
  archived,
  archivedFilesUrl,
  clientName,
  clientNumber,
  closingBinder,
  createdAt,
  createdBy,
  description,
  expiredAt,
  handleArchiveToggle,
  id,
  matterNumber,
  name,
  outstandingSignatures,
  setDeleteModalOpen,
  setEditModalOpen,
  view,
}: TransactionListCardProps) => {
  let date = new Date(createdAt)
  const NameComponent = expiredAt ? "div" : TransactionPrefetchLink

  return (
    <TransactionCard
      key={id}
      interactive
      view={view}
      isExpired={Boolean(expiredAt)}
    >
      <NameComponent id={id} className="flex-grow">
        <TransactionCard.Title text={name} view="LIST">
          {description && (
            <Tooltip2
              minimal
              content={<div className="max-w-[300px]">{description}</div>}
            >
              <Icon icon="info-sign" className="mb-px" />
            </Tooltip2>
          )}
          {expiredAt && (
            <Tooltip2
              className="!hidden group-hover:!block"
              minimal
              content={`Deactivated on ${new Intl.DateTimeFormat(
                "en-US",
                {}
              ).format(new Date(expiredAt))}`}
            >
              <Icon icon="lock" className="mb-px" />
            </Tooltip2>
          )}
        </TransactionCard.Title>
      </NameComponent>
      <div className="w-1/3 flex-shrink-0 py-2 pr-4 text-xs text-[#5C7080]/70 xl:w-1/3">
        <ClientTextContainer>
          {clientName && `${clientName} | `}
          {clientNumber} - {matterNumber}
        </ClientTextContainer>
      </div>

      <div className="ml-auto mr-1 flex w-1/3 flex-shrink-0 items-center xl:w-1/3">
        <div className="w-1/3 break-all p-1 text-xs text-[#5C7080]/80">
          {new Intl.DateTimeFormat("en-US").format(date)}
        </div>
        <div className="w-1/3 break-all p-1 text-xs text-[#5C7080]/80">
          {createdBy?.name ?? ""}
        </div>

        <div className="flex w-1/3 justify-end space-x-1">
          {outstandingSignatures > 0 && !expiredAt && (
            <Link
              href={`/transaction/${id}/manage-signatures`}
              passHref
              legacyBehavior
            >
              <AnchorButton
                minimal
                intent={Intent.WARNING}
                className="ml-1 p-0"
              >
                <Tag minimal round intent={Intent.WARNING}>
                  <span className="text-sm">{outstandingSignatures}</span>
                </Tag>
              </AnchorButton>
            </Link>
          )}
          {Object.keys(closingBinder?.downloads || {}).length > 0 &&
            !expiredAt && (
              <DownloadMenu
                id={closingBinder?.notification_uuid}
                downloadUrls={closingBinder?.downloads}
                extraData={{ target: { name } }}
                type="closing_binder_download"
                linkText=""
                meta={
                  <div className="font-light italic">
                    This will download the binder titled{" "}
                    <span className="font-bold">{closingBinder?.nickname}</span>
                    {closingBinder?.last_build_date && (
                      <span className="text-sm">
                        , last built on{" "}
                        {new Intl.DateTimeFormat("en-US", {
                          dateStyle: "long",
                          timeStyle: "short",
                        }).format(new Date(closingBinder.last_build_date))}
                      </span>
                    )}
                  </div>
                }
              >
                <Button minimal className="p-0">
                  <Icon
                    icon="download"
                    size={22}
                    className="flex items-center"
                  />
                </Button>
              </DownloadMenu>
            )}

          <TransactionCard.Menu
            id={id}
            archived={archived}
            onDelete={() => setDeleteModalOpen(true)}
            onArchive={() => handleArchiveToggle()}
            onEdit={() => setEditModalOpen(true)}
            view={view}
            expiredAt={expiredAt}
            archivedFilesUrl={archivedFilesUrl}
          />
        </div>
      </div>
    </TransactionCard>
  )
}
